<template>
  <div class="col-md-4 ml-auto mr-auto">
    <form @submit.prevent="login">
      <card class="card-login card-plain">

        <div slot="header">
          <div class="logo-container">
            <img src="img/icon-ren.png" alt="">
          </div>
        </div>

        <div>
          <!-- <fg-input v-model="model.email"
                    v-validate="'required|email'"
                    name="email"
                    :error="getError('email')"
                    class="no-border form-control-lg"
                    placeholder="用户名"
                    addon-left-icon="now-ui-icons ui-1_email-85">
          </fg-input>

          <fg-input v-model="model.password"
                    v-validate="'required|min:5'"
                    type="password"
                    name="password"
                    :error="getError('password')"
                    class="no-border form-control-lg"
                    placeholder="密码"
                    addon-left-icon="now-ui-icons ui-1_lock-circle-open">
          </fg-input> -->
          <fg-input v-model="model.email"
                    v-validate="'required'"
                    name="username"
                    :error="getError('username')"
                    class="no-border form-control-lg"
                    placeholder="用户名"
                    addon-left-icon="now-ui-icons ui-1_email-85">
          </fg-input>

          <fg-input v-model="model.password"
                    v-validate="'required'"
                    type="password"
                    name="password"
                    :error="getError('password')"
                    class="no-border form-control-lg"
                    placeholder="密码"
                    addon-left-icon="now-ui-icons ui-1_lock-circle-open">
          </fg-input>
        </div>

        <div slot="footer">
          <n-button native-type="submit" type="primary" round block>
            登录
          </n-button>
          <!-- <div class="pull-left">
            <h6>
              <router-link class="link footer-link" to="/register">
                创建账户
              </router-link>
            </h6>
          </div>

          <div class="pull-right">
            <h6><a href="#pablo" class="link footer-link">需要帮助?</a></h6>
          </div> -->
        </div>
      </card>
    </form>
  </div>
</template>
<script>
import request from '@/util/request';
export default {
  name: 'login',
  data() {
    return {
      model: {
        email: '',
        password: ''
      }
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async login() {
      let isValidForm = await this.$validator.validateAll();
      if (isValidForm) {
        request({
          url: 'api/users/login',
          method: 'post',
          params: {
            account: this.model.email,
            password: this.model.password
          }
        }).then(res => {
          if(res.code != 0) {
            this.$root.$notify({
              message: res.message,
              icon: 'fa fa-gift',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'warning'
            })
            //alert(res.message)
            return;
          }
          sessionStorage.setItem('AccessToken', res.data.access_token)
          sessionStorage.setItem('cities', JSON.stringify(res.data.cities))
          sessionStorage.setItem('disasters', JSON.stringify(res.data.disasters))
          //this.$router.push('/home/city-info')
          this.$router.push('/dashboard')
        });
        // TIP use this.model to send it to api and perform login call
      }
    }
  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
.form-control-lg {
  height: auto;
}
</style>
